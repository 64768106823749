<template>
    <el-dialog :title="titlePrefix" :visible.sync="dialogVisible"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="600px">
        <el-empty v-show="!haveShutDownMenDian" description="暂无关闭门店"></el-empty>
        <div v-show="haveShutDownMenDian">
            <el-alert :title="alterTable" type="warning"/>
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedMenDians" @change="handleCheckedMenDiansChange">
                <el-checkbox v-for="item in menDianCheckboxList" :label="item.id" :key="item.id">{{item.menDianMC}}
                </el-checkbox>
            </el-checkbox-group>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
        </div>
    </el-dialog>
</template>

<script>
    import {checkboxList, deleteBatch} from "@/service/mdgl/MenDianXX";


    export default {
        name: "MenDianXXDelete",
        beforeCreate() {
            this.titlePrefix = '删除门店';
        },
        data() {
            return {
                dialogVisible: false,
                menDianCheckboxList: [],
                alterTable : '只有已经关闭的门店才能进行删除操作',
                checkedMenDians: [],
                isIndeterminate: false,
                checkAll: false,
                haveShutDownMenDian:false,
            }
        },
        mounted() {
            this.getMenDianCheckboxList();
        },
        methods: {
            show() {
                this.dialogVisible = true
                this.getMenDianCheckboxList()
            },
            getMenDianCheckboxList() {
                checkboxList().then((response) => {
                    if(response.data){
                        let resData = response.data
                        this.menDianCheckboxList = resData.filter((menDian) => {
                            return menDian.shiFuKaiQi == "false"
                        })
                    }
                    // this.menDianCheckboxList = response.data.filter((menDian) => {
                    //     return menDian.shiFuKaiQi == "false"
                    // })
                    if(this.menDianCheckboxList.length>0){
                        this.haveShutDownMenDian = true
                    }
                })
            },
            //全选
            handleCheckAllChange(val) {
                let allChecked = this.menDianCheckboxList.map(item=>{
                    return item.id
                })
                this.checkedMenDians = val ? allChecked : [];
                this.isIndeterminate = false;
            },
            //选取门店
            handleCheckedMenDiansChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.menDianCheckboxList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.menDianCheckboxList.length;
            },
            //删除门店
            save() {
                this.loading = true;
                deleteBatch({ids: this.checkedMenDians}).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    this.$emit("submitted", response);
                    this.cancel();
                })
            },
            cancel(){
                this.dialogVisible = false
                this.haveShutDownMenDian = false
                this.isIndeterminate = false;
                this.checkAll= false
                this.checkedMenDians = []
            },
        }
    }
</script>

<style scoped>

</style>
