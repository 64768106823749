<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="600px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="门店名称" prop="menDianMC" class="line">
                {{form.menDianMC}}
            </el-form-item>
            <el-form-item label="组织id" prop="organId" class="line">
                <x-organ-selector v-model="form.organId" size="small"  @selected="deptSelected"
                                   style="width: 100%"></x-organ-selector>
            </el-form-item>
            <el-form-item label="所属行业" prop="suoShuXingYe" class="line">
                <MenDianHangYeSelector v-model="form.suoShuXingYe" ></MenDianHangYeSelector>
            </el-form-item>
            <el-form-item label="经营类型" prop="jingYingLX" class="line">
                <XRadioGroup dictType="T_JING_YING_LEI_XING" v-model="form.jingYingLX"></XRadioGroup>
            </el-form-item>
            <el-form-item label="供货商类型" prop="gongHuoShangLX" class="line">
                <XRadioGroup dictType="T_GONG_HUO_SHANG_LEI_XING" v-model="form.gongHuoShangLX"></XRadioGroup>
            </el-form-item>
            <el-form-item label="省市区" prop="shengDaiMa" class="line">
                <city-picker v-model="form.shengDaiMa" style="width: 400px"></city-picker>
            </el-form-item>
            <el-form-item label="详细地址" prop="xiangXiDZ" class="line">
                <el-input v-model="form.xiangXiDZ" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="门头照片" prop="menTouImg" class="line">
                <x-uploader v-model="form.menTouImg" :list-type="listType" accept="image/jpeg,image/png" :size="1" :on-success="uploadSuccess"></x-uploader>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/mdgl/MenDianXX";
    import {getSelectList} from "@/service/mdgl/MenDianHangYe";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import XUploader from "@/components/x/upload/XUploader";
    import {deepCopy, deepMerge} from "@/util/objects"
    import CityPicker from "@/components/CityPicker/index";
    import MenDianHangYeSelector from "@/view/selector/MenDianHangYeSelector";

    export default {
        components: {CityPicker, XUploader,MenDianHangYeSelector},
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                menDianMC: [], // 门店名称
                organId: [required()], //组织id
                suoShuXingYe: [], // 所属行业
                jingYingLX: [required()], // 经营类型
                gongHuoShangLX: [required()], // 供货商类型
                menTouImg: [required('array',['blur', 'change'])], // 门头照片
                shengDaiMa: [], // 省代码
                shiDaiMa: [], // 市代码
                quDaiMa: [], // 区代码
                menDianDiTuXX: [], // 门店地图信息
                menDianDiZhiXX: [], // 门店地址信息
                jingDu: [], // 经度
                weiDu: [], // 维度
                xiangXiDZ: [required()], // 详细地址
                guiShuShangHu: [], // 归属商户
                shiFuZhuDian: [], // 是否主店
                shiFuKaiQi: [], // 是否开启
                shiFuShanChu: [], // 是否删除
            }
            this.titlePrefix = '门店信息';
            this.listType = "picture-card"
            this.multiple = false
            this.defaultForm = {
                id: null,
                menDianMC: "", // 门店名称
                organId: "",//组织id
                suoShuXingYe: "", // 所属行业
                jingYingLX: "", // 经营类型
                gongHuoShangLX: "", // 供货商类型
                menTouZhaoPian: "", // 门头照片
                menTouImg:[],
                shengDaiMa: "", // 省代码
                shiDaiMa: "", // 市代码
                quDaiMa: "", // 区代码
                menDianDiTuXX: "", // 门店地图信息
                menDianDiZhiXX: "", // 门店地址信息
                jingDu: "", // 经度
                weiDu: "", // 维度
                xiangXiDZ: "", // 详细地址
                guiShuShangHu: "", // 归属商户
                shiFuZhuDian: "false", // 是否主店
                shiFuKaiQi: "true", // 是否开启
                shiFuShanChu: "", // 是否删除
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                options: [],
            }
        },
        mounted() {

        },
        methods: {
            deptSelected(datas) {
                let organ = [];
                if (datas && datas.length > 0) {
                    organ = datas[0];
                }
                this.form.organId = organ.id || "";
                this.form.menDianMC = organ.name || "";
            },
            open(){
                //验证是编辑还是添加并处理
                if (this.isUpdate) {
                    return this.getUpdateService(this.editor.id).then((response) => {
                        this.form = deepMerge(this.defaultForm, response.data);
                        //清空校验
                        this._clearValidate();
                        return response;
                    })
                } else {
                    this.form = deepCopy(this.defaultForm);
                    //清空校验
                    this._clearValidate();
                    return Promise.resolve({code: 200});
                }
            },
            uploadSuccess(response, file, uploadFiles){
                this.form.menTouZhaoPian = file.path
                if(this.form.menTouImg.length>0){
                    this.form.menTouImg.shift()
                }
            }
        }
    }
</script>

<style scoped>

</style>
