<template>
    <el-dialog :title="titlePrefix" :visible.sync="dialogVisible"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="600px">
        <el-alert :title="alterTable" type="warning"/>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedMenDians" @change="handleCheckedMenDiansChange">
            <el-checkbox v-for="item in menDianCheckboxList" :label="item.id" :key="item.id">{{item.menDianMC}}
            </el-checkbox>
        </el-checkbox-group>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {checkboxList, openShutDownMenDian} from "@/service/mdgl/MenDianXX";


    export default {
        name: "MenDianKaiQiGuanBi",
        beforeCreate() {
            this.titlePrefix = '开启/关闭门店';
        },
        data() {
            return {
                dialogVisible: false,
                menDianCheckboxList: [],
                checkedMenDians: [],
                alterTable : '未勾选的门店将关闭',
                isIndeterminate: false,
                checkAll: false,
            }
        },
        mounted() {
            this.getMenDianCheckboxList();
        },
        methods: {
            show() {
                this.dialogVisible = true
                this.getMenDianCheckboxList()
            },
            getMenDianCheckboxList() {
                checkboxList().then((response) => {
                    if(response.data){
                        this.menDianCheckboxList = response.data
                        let checkMenDians = this.menDianCheckboxList.filter((menDian) => {
                            return menDian.shiFuKaiQi == "true"
                        })
                        this.checkedMenDians = checkMenDians.map(item => {
                            return item.id
                        })
                        this.handleCheckedMenDiansChange(this.checkedMenDians)
                    }
                })
            },
            //全选
            handleCheckAllChange(val) {
                let allChecked = this.menDianCheckboxList.map(item => {
                    return item.id
                })
                this.checkedMenDians = val ? allChecked : [];
                this.isIndeterminate = false;
            },
            //选取门店
            handleCheckedMenDiansChange(value) {
                console.log("进来的数据", value)
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.menDianCheckboxList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.menDianCheckboxList.length;
            },
            //开启/关闭门店
            save() {
                this.loading = true;
                openShutDownMenDian({ids: this.checkedMenDians}).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    this.$emit("submitted", response);
                })
                this.cancel();
            },
            cancel() {
                this.dialogVisible = false
            },
        }
    }
</script>

<style scoped>

</style>
