<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="门店名称">
                <el-input v-model="search.menDianMC" size="small"/>
            </x-search-item>
            <x-search-item label="所属行业">
                <MenDianHangYeSelector v-model="search.suoShuXingYe" ></MenDianHangYeSelector>
            </x-search-item>
            <x-search-item label="门店类型">
                <x-selector-one dictType="T_JING_YING_LEI_XING" v-model="search.jingYingLX" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="primary" size="mini" round @click="openShutDown">开启/关闭门店</el-button>
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-button type="danger" size="mini" round @click="deleteMenDian" style="margin-left: auto">删除门店</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column prop="menDianMC" label="门店名称"/>
            <el-table-column label="门头照片" width="100" >
                <template slot-scope="scope">
                    <x-image class="image-style" :attachment="scope.row.menTouImg" :native="native"/>
                </template>
            </el-table-column>
            <el-table-column prop="hangYeMC" label="所属行业"/>
            <el-table-column prop="jingYingLX" label="经营类型">
                <x-dict-show slot-scope="{row}" :code="row.jingYingLX" dictType="T_JING_YING_LEI_XING"/>
            </el-table-column>
            <el-table-column prop="xiangXiDZ" label="门店地址信息"/>
            <el-table-column  label="店长">
                <template slot-scope="scope">
                    <span v-if="!scope.row.dianZhangMC">未设置店长</span>
                    <span v-else>{{scope.row.dianZhangMC}}</span>
                </template>
            </el-table-column>
            <el-table-column width="100px" label="联系电话">
                <template slot-scope="scope">
                    <span v-if="!scope.row.lianXiDianHua">未设置店长</span>
                    <span v-else>{{scope.row.lianXiDianHua}}</span>
                </template>
            </el-table-column>
            <el-table-column width="300px" align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button v-show="scope.row.shiFuZhuDian=='false'" type="primary" size="mini" round @click="setZhuDianFenDian(scope.row.id,'true')">设为主店</el-button>
                    <el-button v-show="scope.row.shiFuZhuDian=='true'" type="warning" size="mini" round @click="setZhuDianFenDian(scope.row.id,'false')">设为分店</el-button>
                    <el-button type="primary" size="mini" round @click="handleUpdate(scope.row.id)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
        <men-dian-kai-qi-guan-bi ref="kaiQiGuanBi" @submitted="refresh"/>
        <men-dian-x-x-delete ref="menDianDelete"/>
    </div>
</template>
<script>
    import * as service from "@/service/mdgl/MenDianXX";
    import {getSelectList} from "@/service/mdgl/MenDianHangYe"
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/mdgl/mdxx/MenDianXXEdit";
    import Detail from "@/view/mdgl/mdxx/MenDianXXDetail";
    import MenDianKaiQiGuanBi from "./MenDianXXKaiQiGuanBi";
    import MenDianXXDelete from "./MenDianXXDelete";
    import 'viewerjs/dist/viewer.css'
    import MenDianHangYeSelector from "@/view/selector/MenDianHangYeSelector";
    import XImage from "@/components/x/upload/XImage";

    export default {
        name: "MenDianXXList",
        mixins: [XTableBase],
        components: {MenDianXXDelete, MenDianKaiQiGuanBi, Edit, Detail,MenDianHangYeSelector,XImage},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                ceshi:false,
                native:true,
                search: {
                    menDianMC: '',
                    jingYingLX: "",
                    suoShuXingYe: ""
                }
            }
        },
        methods: {
            //打开 门店开启/关闭页面
            openShutDown(){
                this.$nextTick(()=>{
                    this.$refs.kaiQiGuanBi.show();
                })
            },
            //删除门店
            deleteMenDian(){
                this.$nextTick(()=>{
                    this.$refs.menDianDelete.show();
                })
            },
            //设置主店分店
            setZhuDianFenDian(id,shiFou) {
                this.service.setZhuDian({id:id,shiFuZhuDian:shiFou}).then((response)=>{
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                })
            },
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
        }
    }
</script>

<style scoped>

    .image-style{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
</style>
